import React, { useEffect, useState } from 'react'
import FirstPrize from "../../assets/Group.svg"
import SecondPrize from "../../assets/Group (1).svg"
import ThirdPrize from "../../assets/Group (2).svg"
import RankingTable from '../Tables/RankingTable'
import axios from '../../axios'

const header = [
  {
    key: "rank",
    label: "Rank"
  },
  {
    key: "username",
    label: "Username"
  },
  {
    key: "referrals",
    label: "Active referrals (last 3m)"
  },
  {
    key: "commission",
    label: "Min. Brokage Commission"
  },
]

function Ranking() {
  const [body, setBody] = useState([])
  const [ongoing, setOngoing] = useState([])
  console.log(body)
  useEffect(() => {

    axios.get("/affilate/campaign/").then(res => {
      console.log(res)
      if (res && res.data) {
        setOngoing(res.data.filter(item => item.status === "ongoing"))
      } else {
        window.location.reload()
      }
    }).catch(err => {
      console.log(err)
    })
    if (!localStorage.getItem("name")) {
      axios.get("auth/profile/").then(res => {
        console.log(res)
        localStorage.setItem("name", res.data.user.firstname)
      }).catch(err => {
        console.log(err)
      });
    }
  }, [])

  useEffect(() => {
    if (ongoing.length > 0) {
      axios.get("/affilate/ranking/").then(res => {
        console.log(res)
        if (res && res.data) {
          setBody(res.data)
        } else {
          window.location.reload()
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [ongoing.length])

  return (
    <div className='lg:px-[5rem] px-[1rem] pt-[3.12rem] pb-[10rem]'>
      <div>
        <div className='hidden lg:flex gap-[2.31rem]'>
          <div className='w-[31.5%] border shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] rounded-[0.625rem] h-[16.25rem] flex flex-col items-center gap-[1.93rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>3rd place</p>
            <img src={ThirdPrize} alt="" className='w-[3.79375rem] h-[4.26988rem]' />
            {ongoing.length > 0 && <p className='font-space text-[2.5rem] font-bold text-[#151515]'>N {Number(ongoing[0]?.third_prize).toLocaleString()}</p>}
          </div>
          <div className='w-[37%] border bg-[#00BA770D] border-[#00BA7780] shadow-[0px_0px_10px_0px_rgba(0,186,119,0.30)] rounded-[0.625rem] h-[19.25rem] flex flex-col items-center gap-[1.62rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>1st place</p>
            <img src={FirstPrize} alt="" className='w-[4.83125rem] h-[5.4375rem]' />
            {ongoing.length > 0 && <p className='font-space text-[3.75rem] font-bold text-[#02915D]'>N {Number(ongoing[0]?.first_prize).toLocaleString()}</p>}
          </div>
          <div className='w-[31.5%] border shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] rounded-[0.625rem] h-[16.25rem] flex flex-col items-center gap-[1.93rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>2nd place</p>
            <img src={SecondPrize} alt="" className='w-[3.79375rem] h-[4.26988rem]' />
            {ongoing.length > 0 && <p className='font-space text-[2.5rem] font-bold text-[#151515]'>N {Number(ongoing[0]?.second_prize).toLocaleString()}</p>}
          </div>
        </div>
        <div className='lg:hidden flex flex-col gap-[2.31rem]'>
          <div className='lg:w-[37%] border bg-[#00BA770D] border-[#00BA7780] shadow-[0px_0px_10px_0px_rgba(0,186,119,0.30)] rounded-[0.625rem] h-[19.25rem] flex flex-col items-center gap-[1.62rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>1st place</p>
            <img src={FirstPrize} alt="" className='w-[4.83125rem] h-[5.4375rem]' />
            {ongoing.length > 0 && <p className='font-space text-[3.75rem] font-bold text-[#02915D]'>N {Number(ongoing[0]?.first_prize).toLocaleString()}</p>}
          </div>
          <div className='lg:w-[31.5%] border shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] rounded-[0.625rem] h-[16.25rem] flex flex-col items-center gap-[1.93rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>2nd place</p>
            <img src={SecondPrize} alt="" className='w-[3.79375rem] h-[4.26988rem]' />
            {ongoing.length > 0 && <p className='font-space text-[2.5rem] font-bold text-[#151515]'>N {Number(ongoing[0]?.second_prize).toLocaleString()}</p>}
          </div>
          <div className='lg:w-[31.5%] border shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] rounded-[0.625rem] h-[16.25rem] flex flex-col items-center gap-[1.93rem] pt-[1.25rem]'>
            <p className='font-space text-[1.25rem] font-medium text-[#151515]'>3rd place</p>
            <img src={ThirdPrize} alt="" className='w-[3.79375rem] h-[4.26988rem]' />
            {ongoing.length > 0 && <p className='font-space text-[2.5rem] font-bold text-[#151515]'>N {Number(ongoing[0]?.third_prize).toLocaleString()}</p>}
          </div>
        </div>
        <RankingTable head={header} body={body} />
      </div>
    </div>
  )
}

export default Ranking