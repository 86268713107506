import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from './pages/Home';
// import Dashboard from "./pages/Dashboard";
import Sidebar from "./components/Sidebar";
import React, { useEffect } from "react";
import TopBar from "./components/TopBar";
import Info from "./components/DashboardModules/Info";
import Home from "./components/DashboardModules/Home";
import Ranking from "./components/DashboardModules/Ranking";
import Refrees from "./components/DashboardModules/Refrees";
import Wallet from "./components/DashboardModules/Wallet";
import Login from "./components/DashboardModules/Login";

function App() {
  console.log(window.location.pathname);
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/home" ||
      window.location.pathname === "ranking" ||
      window.location.pathname === "/refrees" ||
      window.location.pathname === "/wallet"
    ) {
    } else {
      window.location.href = "/";
    }
  }, []);

  console.log(window.location.pathname);

  return (
    <div>
      <Router>
        {window.location.pathname === "/login" ? (
          <Login />
        ) : (
          <div className="flex lg:h-[100vh]">
            <Sidebar />
            <div className="bg-white lg:w-[95%] relative w-[100%] lg:h-[100%] lg:overflow-y-scroll lg:relative">
              <TopBar />
              <div>
                <Routes>
                  {/* <Route path='/' element={<Home />} /> */}
                  <Route path="/" element={<Info />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/ranking" element={<Ranking />} />
                  <Route path="/refrees" element={<Refrees />} />
                  <Route path="/wallet" element={<Wallet />} />
                  {/* <Route path='/login' element={<Login />} /> */}
                </Routes>
              </div>
            </div>
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;
