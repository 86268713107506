import { Dialog } from '@headlessui/react'
import React, { useRef } from 'react'

function ContactSupportModal(props) {
    let completeButtonRef = useRef(null)
    // console.log(props.selectedPayment)


    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            className="relative z-50 "
            as="div"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-[#15151566]" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center">
                {/* The actual dialog panel  */}

                <Dialog.Panel className="mx-auto lg:w-[38.3125rem] w-[95%] flex flex-col items-center bg-inherit rounded-[1.25rem] lg:p-[1.5rem]">
                    <div className='bg-white w-[100%] rounded-[0.75rem] p-[1.5rem]'>
                        <h1 className='font-space font-bold lg:text-[1.5rem] text-[1.25rem]'>To update your bank details, contact support by visiting <a className='underline' href="https://dashboard.gohamma.com/support" target='_blank' rel="noreferrer">dashboard.gohamma.com/support</a></h1>
                        <p
                            ref={completeButtonRef}
                            onClick={() => props.setIsOpen(false)}
                            className="hidden font-poppins text-[#BE8688] lg:text-[1.25rem] font-medium"
                        >
                        </p>
                    </div>
                   
                </Dialog.Panel>
            </div>
        </Dialog>

    )
}

export default ContactSupportModal