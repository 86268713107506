import React, { useEffect, useState } from 'react'
import RefreesTable from '../Tables/RefreesTable'
import axios from '../../axios'

const header = [
  {
    key: "#",
    label:"#"
  },
  {
    key:"username",
    label: "Username"
  },
  {
    key:"stage",
    label: "Stage"
  },
  {
    key:"deposited",
    label:"Amt deposited"
  },
  {
    key:"ref_commission",
    label:"Referral Commission"
  },
  {
    key:"brokage_commission",
    label:"Brokage Commission"
  },
  {
    key:"status",
    label:"Status"
  },
]

// const body = [
//  {

//  },
// ]

function Refrees() {
  const [refrees, setRefrees] = useState([])

  useEffect(() => {
    axios.get("/affilate/referees/").then(res => {
      console.log(res)
      if(res && res.data){
        setRefrees(res.data)
      }else{
        window.location.reload()
      }
    }).catch(err => {
      console.log(err)
    })
    if (!localStorage.getItem("name")) {
      axios.get("auth/profile/").then(res => {
        console.log(res)
        localStorage.setItem("name", res.data.user.firstname)
      }).catch(err => {
        console.log(err)
      });
    }
  }, [])

  return (
    <div className='lg:pl-[4.81rem] pl-[1rem] lg:pr-[2.12rem] pr-[1rem]'>
      <div>
        <RefreesTable head={header} body={refrees}/>
      </div>
    </div>
  )
}

export default Refrees