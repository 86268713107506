import React, { useState } from 'react'
import MobileMenu from './Modals/MobileMenu';
import { useLocation } from 'react-router-dom';

function toPascalCase(str) {
    if (str){

        return str?.replace(/(\w)(\w*)/g, function (_, firstChar, restOfWord) {
            return firstChar.toUpperCase() + restOfWord.toLowerCase();
        });
    }else{
        return "Info"
    }
}

function TopBar() {
    const [isOpen, setIsOpen] = useState(false)
    const params = useLocation()
    const link = params.pathname.split("/")[1]
    console.log(link)
    return (
        <div className='lg:px-[2rem] px-[1rem] py-[1.19rem] border-b-[2px] sticky top-0 bg-white'>
            <div className='flex items-center gap-[1rem]'>
                <svg onClick={() => setIsOpen(!isOpen)} xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none" className='lg:hidden'>
                    <rect width="22" height="2" rx="1" fill="#00BA77" />
                    <rect y="6" width="22" height="2" rx="1" fill="#00BA77" />
                    <rect y="12" width="22" height="2" rx="1" fill="#00BA77" />
                </svg>
                <h1 className='font-space text-[#151515] font-medium lg:text-[1.375rem]'>Referral Dashboard - <span className='font-normal lg:text-[1.25rem]'>{toPascalCase(link)}</span></h1>
                {/* <div className='flex items-center gap-[1.88rem]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M9.0149 15.3974C7.2674 15.3974 5.5199 15.1199 3.8624 14.5649C3.2324 14.3474 2.7524 13.9049 2.5424 13.3274C2.3249 12.7499 2.3999 12.1124 2.7449 11.5424L3.6074 10.1099C3.7874 9.80994 3.9524 9.20994 3.9524 8.85744V6.68994C3.9524 3.89994 6.2249 1.62744 9.0149 1.62744C11.8049 1.62744 14.0774 3.89994 14.0774 6.68994V8.85744C14.0774 9.20244 14.2424 9.80994 14.4224 10.1174L15.2774 11.5424C15.5999 12.0824 15.6599 12.7349 15.4424 13.3274C15.2249 13.9199 14.7524 14.3699 14.1599 14.5649C12.5099 15.1199 10.7624 15.3974 9.0149 15.3974ZM9.0149 2.75244C6.8474 2.75244 5.0774 4.51494 5.0774 6.68994V8.85744C5.0774 9.40494 4.8524 10.2149 4.5749 10.6874L3.7124 12.1199C3.5474 12.3974 3.5024 12.6899 3.5999 12.9374C3.6899 13.1924 3.9149 13.3874 4.2224 13.4924C7.3574 14.5424 10.6799 14.5424 13.8149 13.4924C14.0849 13.4024 14.2949 13.1999 14.3924 12.9299C14.4899 12.6599 14.4674 12.3674 14.3174 12.1199L13.4549 10.6874C13.1699 10.1999 12.9524 9.39744 12.9524 8.84994V6.68994C12.9524 4.51494 11.1899 2.75244 9.0149 2.75244Z" fill="#3E3D3D" />
                        <path d="M10.41 2.95501C10.3575 2.95501 10.305 2.94751 10.2525 2.93251C10.035 2.87251 9.82496 2.82751 9.62246 2.79751C8.98496 2.71501 8.36996 2.76001 7.79246 2.93251C7.58246 3.00001 7.35746 2.93251 7.21496 2.77501C7.07246 2.61751 7.02746 2.39251 7.10996 2.19001C7.41746 1.40251 8.16746 0.88501 9.02246 0.88501C9.87746 0.88501 10.6275 1.39501 10.935 2.19001C11.01 2.39251 10.9725 2.61751 10.83 2.77501C10.7175 2.89501 10.56 2.95501 10.41 2.95501Z" fill="#3E3D3D" />
                        <path d="M9.01514 17.1075C8.27264 17.1075 7.55264 16.8075 7.02764 16.2825C6.50264 15.7575 6.20264 15.0375 6.20264 14.295H7.32764C7.32764 14.7375 7.50764 15.1725 7.82264 15.4875C8.13764 15.8025 8.57264 15.9825 9.01514 15.9825C9.94514 15.9825 10.7026 15.225 10.7026 14.295H11.8276C11.8276 15.8475 10.5676 17.1075 9.01514 17.1075Z" fill="#3E3D3D" />
                    </svg>
                    <div className='flex items-center gap-[0.25rem]'>
                        <div className='w-[1.375rem] h-[1.375rem] bg-[#02915D] rounded-full flex items-center justify-center font-space text-white text-[0.6875rem] font-medium'>
                            A
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M5.99977 8.39995C5.64977 8.39995 5.29977 8.26495 5.03477 7.99995L1.77477 4.73995C1.62977 4.59495 1.62977 4.35495 1.77477 4.20995C1.91977 4.06495 2.15977 4.06495 2.30477 4.20995L5.56477 7.46995C5.80477 7.70995 6.19477 7.70995 6.43477 7.46995L9.69477 4.20995C9.83977 4.06495 10.0798 4.06495 10.2248 4.20995C10.3698 4.35495 10.3698 4.59495 10.2248 4.73995L6.96477 7.99995C6.69977 8.26495 6.34977 8.39995 5.99977 8.39995Z" fill="#151515" />
                        </svg>
                    </div>
                </div> */}
            </div>
            <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} link={link} />
        </div>
    )
}

export default TopBar