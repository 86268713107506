import React, { useEffect, useState } from 'react'
import Trophies from "../../assets/Group 128.svg"
import { FacebookShareButton, LinkedinShareButton } from "react-share"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import axios from '../../axios';

const convertDate = (dateString) => {

  // Parse the input string into a Date object
  const dateObject = new Date(dateString);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get the month and day
  const month = monthNames[dateObject.getMonth()];
  const day = dateObject.getDate();

  // Format the result as a string
  const formattedDate = `${month}-${day}`;

  return formattedDate

}

function getOrdinalSuffix(number) {
  // Check if the number is between 11 and 13 (special cases)
  if (number % 100 >= 11 && number % 100 <= 13) {
    return "th";
  }

  // Determine the suffix based on the last digit
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function Home() {
  const [copied, setCopied] = useState(false)
  const [details, setDetails] = useState({})
  const [position, setPosition] = useState(null)
  console.log(position)

  useEffect(() => {
    axios.get("/affilate/home/").then(res => {
      console.log(res)
      if (res && res.data) {
        setDetails(res.data)
      } else {
        window.location.reload()
      }
    }).catch(err => {
      console.log(err)
    })
    if (!localStorage.getItem("name")) {
      axios.get("auth/profile/").then(res => {
        console.log(res)
        localStorage.setItem("name", res.data.user.firstname)
      }).catch(err => {
        console.log(err)
      });
    }
    axios.get("/affilate/ranking/").then(res => {
      // console.log(res.data.findIndex(a => a.username.includes(localStorage.getItem("name"))))
      if (res.data.findIndex(a => a.username.includes(localStorage.getItem("name"))) >= 0) {
        setPosition(res.data.findIndex(a => a.username.includes(localStorage.getItem("name"))) + 1)
      }

    }).catch(err => {
      console.log(err)
    })
  }, [])

  return (
    <div className='pt-[1.88rem] lg:pl-[4.12rem] lg:pr-[2.38rem] pl-[1rem] pr-[1rem]'>
      <div className='flex flex-col'>
        <Link to="/wallet" className='ml-auto'>
          <button className='font-space font-medium text-white bg-[#02915D] rounded-[0.3125rem] py-[0.81rem] px-[1.25rem]'>Withdraw earnings</button>
        </Link>
        <div className='flex flex-col lg:flex-row gap-[1.25rem] mt-[1.88rem]'>
          <div className='lg:w-[25%] border border-[#8080804D] rounded-[0.3125rem] pb-[3.81rem] pt-[1.25rem] flex flex-col items-center gap-[2.5rem]'>
            <p className='font-space'>Brokage commission</p>
            <div className='flex items-center gap-[1rem]'>
              <h1 className='font-space text-[3.4375rem] text-[#151515]'>N</h1>
              <h1 className='font-space text-[3.4375rem] text-[#00BA77]'>{details.brokerage_comission ? details.brokerage_comission : 0}</h1>
            </div>
          </div>
          <div className='lg:w-[25%] border border-[#8080804D] rounded-[0.3125rem] pb-[3.81rem] pt-[1.25rem] flex flex-col items-center gap-[2.5rem]'>
            <p className='font-space'>Referral commission</p>
            <div className='flex items-center gap-[1rem]'>
              <h1 className='font-space text-[3.4375rem] text-[#151515]'>N</h1>
              <h1 className='font-space text-[3.4375rem] text-[#00BA77]'>{details.referral_comission ? details.referral_comission : 0}</h1>
            </div>
          </div>
          <div className='lg:w-[50%] border border-[#8080804D] rounded-[0.3125rem] pb-[0.75rem] pt-[1.25rem] px-[4.44rem] flex flex-col items-center gap-[2.5rem]'>
            <div className='flex flex-col lg:flex-row justify-between w-[100%]'>
              <div className='flex flex-col items-center gap-[2.5rem]'>
                <p className='font-space'>Amt.Active Referees</p>
                <div className='flex items-center gap-[1rem]'>
                  <h1 className='font-space text-[3.4375rem] text-[#151515]'>N</h1>
                  <h1 className='font-space text-[3.4375rem] text-[#00BA77]'>{details.active_refrees ? details.active_refrees : 0}</h1>
                </div>
              </div>
              <div className='flex flex-col items-center gap-[2.5rem]'>
                <p className='font-space'>Min Brokage comission</p>
                <div className='flex items-center gap-[1rem]'>
                  <h1 className='font-space text-[3.4375rem] text-[#151515]'>N</h1>
                  <h1 className='font-space text-[3.4375rem] text-[#00BA77]'>{details.min_commission ? details.min_commission : 0}</h1>
                </div>
              </div>
            </div>
            <div className='flex items-center gap-[1.25rem]'>
              <p className='font-space text-[0.875rem] text-[#00BA77]'>{convertDate(details.first_day)}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="6" viewBox="0 0 32 6" fill="none">
                <path d="M32 3L27 0.113246L27 5.88675L32 3ZM4.37114e-08 3.5L27.5 3.5L27.5 2.5L-4.37114e-08 2.5L4.37114e-08 3.5Z" fill="#02915D" />
              </svg>
              <p className='font-space text-[0.875rem] text-[#00BA77]'>{convertDate(details.last_day)}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row items-center gap-[1.69rem] mt-[2.5rem]'>
          <div className='flex flex-col lg:flex-row items-center gap-[6rem] rounded-[0.3125rem] border border-[#80808080] lg:pl-[1.88rem] lg:pr-[8.07rem] pl-[3rem] pr-[3rem] pt-[1.28rem] pb-[1.29rem]'>
            <div >
              <h1 className='font-space text-[1.25rem] font-medium text-[#151515] whitespace-nowrap mb-[1rem]'>Leaderboard ranking</h1>
              {position && <p className='font-space text-center lg:text-left text-[4.375rem] font-bold text-[#02915D]'>{position}<span className='text-[1.875rem] text-[#151515] mb-[2rem]'>{getOrdinalSuffix(position)}</span></p>}
              <Link to="/ranking">
                <button className='font-space font-medium px-[1.44rem] py-[0.84rem] bg-[#02915D] text-white rounded-[0.3125rem]'>View Leaderboard</button>
              </Link>
            </div>
            <img src={Trophies} alt='' />
          </div>
          <div className='bg-[#02915D0D] pl-[2.88rem] pr-[2.44rem] border border-[#02915D] pt-[1.25rem] pb-[2.62rem] rounded-[0.3125rem]'>
            <div className='flex items-center mb-[1.91rem]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="68" height="50" viewBox="0 0 68 50" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15351 3.69541C8.36385 5.3757 7.09147 8.49835 6.36784 12.4527C5.86727 15.1869 5.62948 18.3176 5.99219 21.6452C6.63061 27.5021 8.88895 33.4876 13.4213 38.0566C17.188 41.8542 22.5256 44.6755 29.8163 45.6295C29.868 45.6399 29.9212 45.6395 29.9726 45.6283C30.0241 45.6172 30.0727 45.5956 30.1154 45.5648C30.1582 45.5341 30.1941 45.4948 30.2211 45.4495C30.248 45.4042 30.2653 45.3539 30.2719 45.3016C30.2785 45.2494 30.2742 45.1963 30.2594 45.1457C30.2446 45.0952 30.2196 45.0482 30.1859 45.0078C30.1521 44.9673 30.1104 44.9343 30.0633 44.9107C30.0163 44.8871 29.9648 44.8734 29.9123 44.8706C22.7915 44.0014 17.5578 41.2993 13.8513 37.6285C9.36855 33.1876 7.11467 27.3329 6.43922 21.5961C6.05242 18.3149 6.26076 15.2226 6.73144 12.5196C7.41447 8.59522 8.64983 5.49043 9.41584 3.81906C9.43199 3.78437 9.43371 3.74468 9.42062 3.70873C9.40752 3.67277 9.38069 3.64349 9.34602 3.62733C9.31135 3.61117 9.27169 3.60945 9.23575 3.62255C9.19982 3.63565 9.17056 3.6625 9.15441 3.69719L9.15351 3.69541Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.116 35.6781C25.3917 35.8875 27.7999 37.7646 28.3197 39.4972C29.2298 42.5328 26.9648 44.2291 26.8795 44.2934C26.7832 43.276 26.5802 41.4864 26.2764 40.223C25.9748 38.9673 25.4341 36.8923 25.116 35.6781Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.116 35.6781C25.4355 36.8923 25.9749 38.9672 26.276 40.223C26.5794 41.4859 26.7824 43.2764 26.8787 44.2934H26.8765C26.8765 44.2934 24.0828 42.3131 24.0212 39.9748C23.9597 37.69 25.0919 35.6562 25.0919 35.6562L25.116 35.6781Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1084 0.0055542C11.9259 0.044392 10.3238 0.410449 9.67512 1.13676C8.53881 2.41082 9.2562 3.75496 9.28386 3.80452C9.5627 3.33043 10.07 2.50367 10.5058 1.95682C10.9417 1.40996 11.6756 0.524731 12.1084 0.0055542Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1084 0.00535672C11.6757 0.524533 10.9395 1.41334 10.5059 1.95662C10.0723 2.4999 9.56277 3.33023 9.28394 3.80432C9.28394 3.80432 11.0979 3.47621 11.6618 2.34991C12.2133 1.24995 12.1254 0 12.1254 0L12.1084 0.00535672Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.35249 7.27649C2.32126 7.54121 2.0741 9.8813 2.75669 11.096C3.95278 13.224 6.10272 12.8749 6.18436 12.8611C5.67219 12.2638 4.78884 11.1942 4.2508 10.35C3.71276 9.50587 2.85484 8.1028 2.35249 7.27649Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.35254 7.27651C2.85489 8.10281 3.71638 9.51124 4.25085 10.35C4.78532 11.1889 5.67046 12.2638 6.18485 12.8611H6.18708C6.18708 12.8611 6.58281 10.2523 5.32068 8.96707C4.08712 7.71176 2.35477 7.25195 2.35477 7.25195L2.35254 7.27651Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.169922 17.2034C0.226135 17.4868 0.75213 19.9818 1.86123 21.0059C3.8046 22.8005 5.90635 21.7202 5.98398 21.6804C5.25677 21.234 3.98885 20.4305 3.15279 19.7376C2.31673 19.0448 0.963599 17.8873 0.169922 17.2034Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.169937 17.2034C0.963614 17.8873 2.3221 19.0515 3.15281 19.7381C3.98351 20.4247 5.25679 21.2358 5.98265 21.6804H5.98488C5.98488 21.6804 5.52269 18.8582 3.79212 17.9551C2.10171 17.073 0.162354 17.1779 0.162354 17.1779L0.169937 17.2034Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2185 26.761C11.3501 26.961 12.49 28.7404 12.4306 29.9904C12.3267 32.1814 10.4583 32.7675 10.3887 32.7885C10.5595 32.1028 10.8388 30.8881 10.9307 29.9935C11.0226 29.0989 11.1475 27.6266 11.2185 26.761Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2184 26.761C11.1475 27.6266 11.0226 29.1051 10.9311 29.9935C10.8397 30.8819 10.5595 32.1028 10.3886 32.7885C10.3886 32.7885 9.02122 30.8533 9.51777 29.3127C10.0027 27.8083 11.2086 26.7414 11.2086 26.7414L11.2184 26.761Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7384 7.07294C12.638 7.26445 11.7333 8.9434 10.721 9.44338C8.94668 10.3197 7.66672 9.10054 7.61987 9.05456C8.23554 8.88938 9.31519 8.57868 10.062 8.25994C10.8089 7.94121 12.0246 7.39569 12.7384 7.07294Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7386 7.07252C12.0247 7.39572 10.8037 7.94347 10.0617 8.25997C9.31981 8.57648 8.23526 8.88941 7.61914 9.05459C7.61914 9.05459 8.54755 7.12743 9.98054 6.84574C11.381 6.56941 12.7484 7.05332 12.7484 7.05332L12.7386 7.07252Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.13354 37.3352C4.37624 37.545 6.54268 39.3749 8.32322 39.5753C11.4426 39.9262 12.8292 37.7004 12.8787 37.6151C11.8606 37.6798 10.0645 37.7647 8.77426 37.6839C7.49608 37.6039 5.37514 37.4361 4.13354 37.3352Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.13351 37.3347C5.37511 37.4361 7.49649 37.6026 8.77645 37.6838C10.0649 37.7642 11.8606 37.6798 12.8809 37.6151C12.8809 37.6151 10.561 35.4638 8.24109 35.7509C5.97471 36.0316 4.10986 37.3182 4.10986 37.3182L4.13351 37.3347Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96275 0.828979C5.9217 1.01335 5.57997 2.64632 5.97301 3.62441C6.66229 5.33818 8.2037 5.43371 8.25902 5.43684C7.94137 4.92748 7.39619 4.02082 7.07809 3.33112C6.76 2.64141 6.25675 1.50038 5.96275 0.828979Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9624 0.828995C6.25641 1.49861 6.76188 2.64589 7.07774 3.33113C7.39361 4.01637 7.94102 4.9275 8.25778 5.43685C8.25778 5.43685 8.7231 3.63558 7.92674 2.51375C7.14913 1.41826 5.96374 0.811584 5.96374 0.811584L5.9624 0.828995Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2197 48.9463C20.182 48.0334 21.2688 46.2986 22.9771 45.4786C24.6853 44.6585 26.7206 45.1848 26.7206 45.1848C26.7206 45.1848 25.9104 46.4888 23.9131 47.4098C22.8557 47.8972 20.7455 48.5178 19.7546 48.7977L19.2197 48.9463Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7209 45.1848C26.7209 45.1848 27.3405 47.9128 25.3441 48.957C23.0037 50.1815 19.22 48.9463 19.22 48.9463L19.7553 48.7976C20.7449 48.5177 22.8564 47.8972 23.9138 47.4097C25.9107 46.4888 26.7209 45.1848 26.7209 45.1848Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10889 32.0403C5.12965 32.085 6.6715 31.7542 8.04158 32.26C9.41167 32.7657 10.2116 34.1742 10.2116 34.1742C10.2116 34.1742 9.05654 34.4255 7.47633 33.8139C6.63937 33.4903 5.16356 32.6532 4.47829 32.2568L4.10889 32.0403Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2131 34.1741C10.2131 34.1741 9.03793 35.9803 7.38946 35.4357C5.45724 34.7973 4.11035 32.0403 4.11035 32.0403L4.47975 32.2568C5.16502 32.6545 6.64084 33.4902 7.47779 33.8139C9.05801 34.4255 10.2131 34.1741 10.2131 34.1741Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.24976 41.156C10.2852 40.7431 11.6745 39.7275 13.265 39.6204C14.8555 39.5132 16.2795 40.5578 16.2795 40.5578C16.2795 40.5578 15.2409 41.3234 13.3944 41.4194C12.4169 41.4703 10.5748 41.2958 9.71463 41.206L9.24976 41.156Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2786 40.5588C16.2786 40.5588 15.9128 42.8801 14.0283 43.0748C11.8191 43.3006 9.24976 41.1552 9.24976 41.1552L9.71374 41.2061C10.5739 41.2954 12.416 41.4703 13.3935 41.4195C15.24 41.3244 16.2786 40.5588 16.2786 40.5588Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2477 33.5818C15.9684 34.4934 15.2015 35.7478 15.2216 37.1084C15.2417 38.4691 16.2303 39.6119 16.2303 39.6119C16.2303 39.6119 16.8103 38.6744 16.7625 37.0933C16.7375 36.2571 16.4609 34.6996 16.3244 33.9728L16.2477 33.5818Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2295 39.6129C16.2295 39.6129 18.1813 39.1388 18.2139 37.5201C18.2523 35.6219 16.2478 33.5818 16.2478 33.5818L16.3236 33.9738C16.4601 34.7006 16.7367 36.2581 16.7617 37.0942C16.8086 38.6745 16.2295 39.6129 16.2295 39.6129Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.222 42.6848C21.6492 42.6057 19.3324 42.1116 18.9233 39.263C18.6578 37.4171 19.6956 35.8381 20.0703 34.665L21.3195 38.8358C21.6684 39.9965 22.3514 42.7013 22.3514 42.7013L22.222 42.6848Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.462 34.9094C21.1811 35.3911 22.673 36.5259 23.1044 37.8972C23.8517 40.2708 22.7123 42.1654 22.4156 42.602L22.3482 42.6971C22.3482 42.6971 21.6652 39.9936 21.3163 38.8316C20.9674 37.6696 20.0671 34.6608 20.0671 34.6608L20.462 34.9094Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70647 18.292C6.54274 17.8831 6.1265 16.3622 7.85706 15.0162C8.97731 14.144 10.2782 14.0341 11.1968 13.8404L8.91128 16.2403C8.27465 16.9099 6.74573 18.3831 6.74573 18.3831L6.70647 18.292Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1811 14.1959C11.1364 14.8539 10.9473 16.2695 10.204 17.0725C8.91691 18.4631 7.23453 18.4238 6.83434 18.3926L6.74512 18.3837C6.74512 18.3837 8.27403 16.9105 8.91066 16.2409C9.5473 15.5713 11.1971 13.8387 11.1971 13.8387L11.1811 14.1959Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.60708 26.1343C6.20556 26.4526 4.47589 27.6342 2.40403 26.1562C1.0616 25.1986 0.685958 23.5701 0.163086 22.6121L3.59209 24.3607C4.5495 24.8469 6.69631 26.0606 6.69631 26.0606L6.60708 26.1343Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.563312 22.5121C1.31059 22.3461 2.94747 22.084 4.08779 22.6461C6.06239 23.6174 6.57768 25.5031 6.67539 25.9611L6.69501 26.062C6.69501 26.062 4.5491 24.8482 3.59258 24.3621C2.63606 23.8759 0.163574 22.6135 0.163574 22.6135L0.563312 22.5121Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.14705 8.69035C6.76471 8.74481 5.18405 8.8515 4.40197 7.09711C3.89426 5.9601 4.27169 4.75612 4.29757 3.9325L5.86173 6.38374C6.29849 7.0663 7.23181 8.6774 7.23181 8.6774L7.14705 8.69035Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.59538 4.01999C5.14547 4.19856 6.31123 4.65345 6.83901 5.45476C7.75359 6.84265 7.36812 8.26715 7.25748 8.60285L7.23161 8.67607C7.23161 8.67607 6.29829 7.06497 5.86152 6.38374C5.42475 5.70252 4.29736 3.93384 4.29736 3.93384L4.59538 4.01999Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13176 23.296C6.86943 22.9509 6.41794 21.3733 7.36018 19.8461C8.09274 18.6555 9.41241 18.323 10.232 17.8971L8.68877 20.7724C8.25914 21.576 7.19199 23.3714 7.19199 23.3714L7.13176 23.296Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.31 18.2377C10.4372 18.8743 10.6295 20.2666 10.1316 21.2233C9.27275 22.8795 7.66577 23.2822 7.27629 23.3567L7.19019 23.3714C7.19019 23.3714 8.25734 21.5742 8.68697 20.7724C9.1166 19.9707 10.2302 17.8971 10.2302 17.8971L10.31 18.2377Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7639 46.6871C12.7639 46.6871 13.9832 44.3068 15.9315 43.4309C18.4299 42.3091 21.0928 43.3863 21.7197 43.6742L21.8571 43.7398C21.8571 43.7398 19.9864 44.1537 17.9801 44.7702C16.9768 45.0782 15.6727 45.5572 14.619 45.9594L12.7639 46.6871Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6563 44.2122C21.2503 45.0737 20.2287 46.8554 18.5503 47.3205C16.3125 47.941 12.7639 46.6871 12.7639 46.6871L14.6203 45.9594C15.6741 45.5577 16.9781 45.0782 17.9815 44.7702C19.9891 44.1537 21.8584 43.7399 21.8584 43.7399L21.6563 44.2122Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0192 4.46411C14.0192 4.46411 12.7606 3.64852 11.5681 3.7686C10.0401 3.92261 9.04696 5.1922 8.82256 5.50737L8.77393 5.5779C8.77393 5.5779 9.80004 5.25515 10.9471 4.99311C11.5208 4.86231 12.2886 4.72972 12.9132 4.63062L14.0192 4.46411Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00458 5.74892C9.44268 6.05382 10.4376 6.63371 11.3834 6.38997C12.6446 6.06498 14.0192 4.46191 14.0192 4.46191L12.9132 4.62842C12.2886 4.72753 11.5208 4.86011 10.9471 4.99091C9.79915 5.25295 8.77393 5.57571 8.77393 5.57571L9.00458 5.74892Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000488281 28.644C0.000488281 28.644 1.76317 27.2994 3.53434 27.3418C5.80383 27.3963 7.40993 29.156 7.77754 29.5971L7.85696 29.6953C7.85696 29.6953 6.30931 29.3355 4.58856 29.0788C3.72796 28.9507 2.58094 28.8422 1.64896 28.7663L0.000488281 28.644Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.53723 29.9742C6.92558 30.4733 5.52471 31.4407 4.10198 31.188C2.2059 30.8514 -0.000244141 28.6435 -0.000244141 28.6435L1.64823 28.7636C2.58021 28.8395 3.72723 28.9479 4.58783 29.0761C6.30857 29.3328 7.85622 29.6926 7.85622 29.6926L7.53723 29.9742Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5214 10.3358C12.5214 10.3358 10.798 9.77466 9.4431 10.2862C7.70629 10.9416 6.94072 12.7339 6.77654 13.1718L6.74219 13.2692C6.74219 13.2692 7.83924 12.5732 9.09824 11.9098C9.72773 11.5782 10.5834 11.1849 11.2821 10.8742L12.5214 10.3358Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06397 13.3995C7.67072 13.6196 9.01314 13.9875 10.0419 13.4085C11.4142 12.6362 12.5216 10.3358 12.5216 10.3358L11.2813 10.8742C10.5827 11.1867 9.72696 11.5782 9.09746 11.9099C7.83846 12.5732 6.74097 13.2692 6.74097 13.2692L7.06397 13.3995Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8818 21.7903C10.8818 21.7903 9.03838 22.6438 8.31474 24.0982C7.38767 25.9616 8.12424 28.0177 8.32277 28.5025L8.36739 28.6092C8.36739 28.6092 8.73857 27.2003 9.26724 25.695C9.53136 24.9428 9.93645 23.9665 10.2719 23.1782L10.8818 21.7903Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.73323 28.4699C9.39931 28.1877 10.7819 27.4659 11.187 26.2052C11.7259 24.524 10.8827 21.7902 10.8827 21.7902L10.2742 23.1781C9.93691 23.9665 9.5336 24.9428 9.26949 25.695C8.74081 27.2003 8.36963 28.6092 8.36963 28.6092L8.73323 28.4699Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.0752 12.1674C1.0752 12.1674 3.09932 12.3353 4.27846 13.4517C5.78952 14.8829 5.8038 17.0677 5.78372 17.5904L5.77703 17.7065C5.77703 17.7065 4.94365 16.511 3.93047 15.2794C3.42366 14.6638 2.70984 13.8857 2.12228 13.2616L1.0752 12.1674Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.38577 17.7011C4.66347 17.665 3.11716 17.4628 2.3043 16.4177C1.22108 15.0245 1.0752 12.1674 1.0752 12.1674L2.12317 13.2616C2.71073 13.8865 3.42455 14.6638 3.93136 15.2794C4.94499 16.511 5.77703 17.7065 5.77703 17.7065L5.38577 17.7011Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3157 28.5753C14.3157 28.5753 12.4807 29.9118 12.0029 31.6635C11.3908 33.9081 12.6449 36.0022 12.9679 36.4906L13.0411 36.5973C13.0411 36.5973 13.1419 34.9723 13.3948 33.2072C13.5215 32.3247 13.7518 31.1667 13.9503 30.2292L14.3157 28.5753Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4105 36.3656C14.0797 35.9111 15.4391 34.8197 15.6082 33.3492C15.8339 31.389 14.3144 28.5753 14.3144 28.5753L13.949 30.227C13.7505 31.1644 13.5203 32.3224 13.3936 33.205C13.1406 34.9701 13.0398 36.595 13.0398 36.595L13.4105 36.3656Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.5851 3.81731C59.3529 5.48867 60.5865 8.59391 61.2695 12.5179C61.7398 15.2209 61.9486 18.3132 61.5618 21.5943C60.8859 27.3311 58.6324 33.1858 54.1496 37.6267C50.4422 41.2989 45.2086 43.9997 38.0887 44.8689C38.0361 44.8717 37.9847 44.8853 37.9376 44.9089C37.8906 44.9325 37.8489 44.9656 37.8151 45.006C37.7814 45.0465 37.7563 45.0934 37.7415 45.144C37.7267 45.1945 37.7225 45.2476 37.7291 45.2999C37.7357 45.3522 37.753 45.4025 37.7799 45.4478C37.8068 45.4931 37.8428 45.5323 37.8855 45.5631C37.9283 45.5938 37.9769 45.6155 38.0283 45.6266C38.0798 45.6377 38.133 45.6381 38.1846 45.6278C45.4754 44.6738 50.813 41.8525 54.5797 38.0548C59.112 33.4858 61.3704 27.5003 62.0088 21.6434C62.3715 18.3159 62.1337 15.1852 61.6331 12.4509C60.9095 8.49659 59.6371 5.37394 58.8475 3.69365C58.8395 3.67647 58.8282 3.66104 58.8142 3.64824C58.8002 3.63543 58.7839 3.6255 58.7661 3.61901C58.7483 3.61252 58.7294 3.60961 58.7105 3.61043C58.6916 3.61125 58.673 3.61579 58.6558 3.62379C58.6387 3.63179 58.6233 3.6431 58.6105 3.65706C58.5977 3.67103 58.5877 3.68738 58.5812 3.70519C58.5748 3.72299 58.5718 3.7419 58.5727 3.76083C58.5735 3.77976 58.578 3.79835 58.586 3.81552L58.5851 3.81731Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.8841 35.6781C42.6083 35.8875 40.2001 37.7646 39.6803 39.4972C38.7702 42.5328 41.0353 44.2291 41.1205 44.2934C41.2168 43.276 41.4198 41.4864 41.7237 40.223C42.0252 38.9673 42.566 36.8923 42.8841 35.6781Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.884 35.6781C42.5646 36.8923 42.0252 38.9672 41.7241 40.223C41.4207 41.4859 41.2177 43.2764 41.1213 44.2934H41.1236C41.1236 44.2934 43.9173 42.3131 43.9788 39.9748C44.0404 37.69 42.9081 35.6562 42.9081 35.6562L42.884 35.6781Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.8916 0.00543213C56.0741 0.0442699 57.6762 0.410327 58.3248 1.13664C59.4611 2.4098 58.7438 3.75484 58.7161 3.80439C58.4373 3.3303 57.93 2.50355 57.4941 1.9567C57.0583 1.40984 56.3244 0.524609 55.8916 0.00543213Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.8915 0.00535672C56.3242 0.524533 57.0603 1.41334 57.494 1.95662C57.9276 2.4999 58.4371 3.33023 58.716 3.80432C58.716 3.80432 56.902 3.47621 56.3381 2.34991C55.7866 1.24995 55.8745 0 55.8745 0L55.8915 0.00535672Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.6475 7.27649C65.6788 7.54121 65.9259 9.8813 65.2433 11.096C64.0473 13.224 61.8973 12.8749 61.8157 12.8611C62.3278 12.2638 63.2112 11.1942 63.7492 10.35C64.2873 9.50587 65.1452 8.1028 65.6475 7.27649Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.6474 7.27651C65.1451 8.10281 64.2836 9.51124 63.7491 10.35C63.2147 11.1889 62.3277 12.2638 61.8151 12.8611C61.8151 12.8611 61.4194 10.2523 62.6815 8.96707C63.9151 7.71176 65.6474 7.25195 65.6474 7.25195V7.27651Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.8302 17.2034C67.774 17.4868 67.2502 19.9818 66.1389 21.0059C64.1955 22.8005 62.0937 21.7202 62.0161 21.6804C62.7433 21.234 64.0112 20.4305 64.8473 19.7376C65.6834 19.0448 67.0365 17.8873 67.8302 17.2034Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.8301 17.2034C67.0365 17.8873 65.678 19.0515 64.8473 19.7381C64.0166 20.4247 62.7433 21.2358 62.0156 21.6804C62.0156 21.6804 62.4778 18.8582 64.2084 17.9551C65.8988 17.073 67.8382 17.1779 67.8382 17.1779L67.8301 17.2034Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7813 26.761C56.6474 26.961 55.5098 28.7404 55.5691 29.9904C55.6731 32.1814 57.5415 32.7675 57.6111 32.7885C57.4402 32.1028 57.161 30.8881 57.069 29.9935C56.9771 29.0989 56.8522 27.6266 56.7813 26.761Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7812 26.761C56.8522 27.6266 56.9771 29.1051 57.0686 29.9935C57.16 30.8819 57.4402 32.1028 57.6111 32.7885C57.6111 32.7885 58.9785 30.8533 58.4819 29.3127C57.997 27.8083 56.7911 26.7414 56.7911 26.7414L56.7812 26.761Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.2615 7.07294C55.3619 7.26445 56.2666 8.9434 57.2789 9.44338C59.0532 10.3197 60.3332 9.10054 60.38 9.05456C59.7643 8.88938 58.6847 8.57868 57.9379 8.25994C57.191 7.94121 55.9753 7.39569 55.2615 7.07294Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.2614 7.0725C55.9753 7.39571 57.1963 7.94345 57.9383 8.25996C58.6802 8.57646 59.7647 8.8894 60.3809 9.05457C60.3809 9.05457 59.4525 7.12741 58.0195 6.84573C56.6199 6.57074 55.2507 7.05643 55.2507 7.05643L55.2614 7.0725Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8665 37.3352C63.6238 37.545 61.4574 39.3749 59.6768 39.5753C56.5575 39.9262 55.1709 37.7004 55.1213 37.6151C56.1394 37.6798 57.9356 37.7647 59.2258 37.6839C60.504 37.6039 62.6249 37.4361 63.8665 37.3352Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8663 37.3347C62.6247 37.4361 60.5033 37.6026 59.2234 37.6838C57.9349 37.7642 56.1392 37.6798 55.1189 37.6151C55.1189 37.6151 57.4388 35.4638 59.7587 35.7509C62.0251 36.0316 63.8899 37.3182 63.8899 37.3182L63.8663 37.3347Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.0372 0.828979C62.0783 1.01335 62.42 2.64632 62.027 3.62441C61.3377 5.33818 59.7963 5.43371 59.741 5.43684C60.0586 4.92748 60.6038 4.02082 60.9219 3.33112C61.24 2.64141 61.7432 1.50038 62.0372 0.828979Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.0373 0.828995C61.7433 1.49861 61.2379 2.64589 60.922 3.33113C60.6061 4.01637 60.0587 4.9275 59.7411 5.43685C59.7411 5.43685 59.2758 3.63558 60.0721 2.51375C60.8497 1.41826 62.0351 0.811584 62.0351 0.811584L62.0373 0.828995Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.78 48.9464C47.8177 48.0335 46.7309 46.2987 45.0226 45.4786C43.3144 44.6586 41.2795 45.1849 41.2795 45.1849C41.2795 45.1849 42.0893 46.4889 44.0866 47.4098C45.144 47.8973 47.2542 48.5178 48.2451 48.7977L48.78 48.9464Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M41.2795 45.1849C41.2795 45.1849 40.6594 47.9129 42.6559 48.9571C44.9963 50.1816 48.78 48.9463 48.78 48.9463L48.2446 48.7977C47.2551 48.5178 45.1435 47.8973 44.0862 47.4098C42.0893 46.4888 41.2795 45.1849 41.2795 45.1849Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8913 32.0403C62.8705 32.0849 61.3287 31.7541 59.9586 32.2599C58.5885 32.7657 57.7886 34.1741 57.7886 34.1741C57.7886 34.1741 58.9436 34.4254 60.5238 33.8139C61.3608 33.4902 62.8366 32.6532 63.5219 32.2568L63.8913 32.0403Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.7871 34.1741C57.7871 34.1741 58.9622 35.9803 60.6107 35.4357C62.5429 34.7973 63.8898 32.0403 63.8898 32.0403L63.5204 32.2568C62.8351 32.6545 61.3593 33.4902 60.5224 33.8139C58.9422 34.4255 57.7871 34.1741 57.7871 34.1741Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.7502 41.156C57.7148 40.7431 56.3255 39.7275 54.735 39.6204C53.1445 39.5132 51.7205 40.5578 51.7205 40.5578C51.7205 40.5578 52.7591 41.3234 54.6056 41.4194C55.5831 41.4703 57.4252 41.2958 58.2854 41.206L58.7502 41.156Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.7214 40.5588C51.7214 40.5588 52.0873 42.8801 53.9718 43.0748C56.181 43.3006 58.7503 41.1552 58.7503 41.1552L58.2863 41.2061C57.4262 41.2954 55.5841 41.4703 54.6066 41.4195C52.76 41.3244 51.7214 40.5588 51.7214 40.5588Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.752 33.5818C52.0313 34.4934 52.7982 35.7478 52.7781 37.1085C52.758 38.4692 51.7694 39.612 51.7694 39.612C51.7694 39.612 51.1894 38.6745 51.2372 37.0933C51.2622 36.2572 51.5388 34.6997 51.6753 33.9729L51.752 33.5818Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.7704 39.6128C51.7704 39.6128 49.8185 39.1387 49.7859 37.52C49.7476 35.6219 51.7521 33.5818 51.7521 33.5818L51.6762 33.9737C51.5397 34.7005 51.2631 36.258 51.2381 37.0941C51.1913 38.6744 51.7704 39.6128 51.7704 39.6128Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.7781 42.6846C46.3509 42.6056 48.6677 42.1114 49.0768 39.2628C49.3445 37.4169 48.3045 35.838 47.9298 34.6648L46.6806 38.8356C46.3317 39.9963 45.6487 42.7011 45.6487 42.7011L45.7781 42.6846Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.538 34.9094C46.8188 35.3911 45.3269 36.5259 44.8955 37.8972C44.1482 40.2708 45.2877 42.1654 45.5843 42.602L45.6517 42.6971C45.6517 42.6971 46.3347 39.9936 46.6836 38.8316C47.0325 37.6696 47.9328 34.6608 47.9328 34.6608L47.538 34.9094Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.2933 18.2919C61.4571 17.883 61.8733 16.362 60.1428 15.0161C59.0225 14.1438 57.7216 14.034 56.803 13.8403L59.0885 16.2402C59.7252 16.9098 61.2541 18.383 61.2541 18.383L61.2933 18.2919Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.819 14.1959C56.8637 14.8539 57.0528 16.2695 57.7961 17.0725C59.0832 18.4631 60.7656 18.4238 61.1658 18.3926L61.255 18.3837C61.255 18.3837 59.7261 16.9105 59.0894 16.2409C58.4528 15.5713 56.803 13.8387 56.803 13.8387L56.819 14.1959Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.3929 26.1343C61.7944 26.4526 63.5241 27.6342 65.5959 26.1562C66.9383 25.1986 67.314 23.5701 67.8369 22.6121L64.4079 24.3607C63.4513 24.8469 61.3054 26.0606 61.3054 26.0606L61.3929 26.1343Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.4364 22.5121C66.6891 22.3461 65.0522 22.084 63.9119 22.6461C61.9373 23.6174 61.422 25.5031 61.3243 25.9611L61.3047 26.062C61.3047 26.062 63.4506 24.8482 64.4071 24.3621C65.3636 23.8759 67.8361 22.6135 67.8361 22.6135L67.4364 22.5121Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.8531 8.69035C61.2354 8.74481 62.8138 8.8515 63.5982 7.09711C64.1059 5.9601 63.7284 4.75612 63.7026 3.9325L62.1384 6.38374C61.7016 7.0663 60.7683 8.6774 60.7683 8.6774L60.8531 8.69035Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.4046 4.01999C62.8545 4.19856 61.6887 4.65345 61.1609 5.45476C60.2464 6.84265 60.6318 8.26715 60.7425 8.60285L60.7683 8.67606C60.7683 8.67606 61.7016 7.06496 62.1384 6.3824C62.5752 5.69984 63.7026 3.9325 63.7026 3.9325L63.4046 4.01999Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.868 23.296C61.1304 22.9509 61.5818 21.3733 60.6396 19.8461C59.907 18.6555 58.5874 18.323 57.7678 17.8971L59.311 20.7724C59.7406 21.576 60.8078 23.3714 60.8078 23.3714L60.868 23.296Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.6899 18.2377C57.5627 18.8743 57.3704 20.2666 57.8683 21.2233C58.7271 22.8795 60.3341 23.2822 60.7236 23.3567L60.8097 23.3714C60.8097 23.3714 59.7425 21.5742 59.3129 20.7724C58.8833 19.9707 57.7697 17.8971 57.7697 17.8971L57.6899 18.2377Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.2362 46.6871C55.2362 46.6871 54.0169 44.3068 52.0687 43.4309C49.5703 42.3091 46.9073 43.3863 46.2805 43.6742L46.1431 43.7398C46.1431 43.7398 48.0137 44.1537 50.02 44.7702C51.0234 45.0782 52.3274 45.5572 53.3812 45.9594L55.2362 46.6871Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.3435 44.2122C46.7494 45.0737 47.7711 46.8554 49.4495 47.3205C51.6873 47.941 55.2359 46.6871 55.2359 46.6871L53.3795 45.9594C52.3257 45.5577 51.0216 45.0782 50.0183 44.7702C48.0107 44.1537 46.1414 43.7399 46.1414 43.7399L46.3435 44.2122Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.981 4.46411C53.981 4.46411 55.2395 3.64852 56.432 3.7686C57.9601 3.92261 58.9531 5.1922 59.1776 5.50737L59.2262 5.5779C59.2262 5.5779 58.2001 5.25515 57.0531 4.99311C56.4793 4.86231 55.7115 4.72972 55.0869 4.63062L53.981 4.46411Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.9955 5.74892C58.5574 6.05382 57.5625 6.63371 56.6167 6.38997C55.3555 6.06498 53.981 4.46191 53.981 4.46191L55.0869 4.62842C55.7115 4.72753 56.4793 4.86011 57.0531 4.99091C58.201 5.25295 59.2262 5.57571 59.2262 5.57571L58.9955 5.74892Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.999 28.644C67.999 28.644 66.2364 27.2994 64.4652 27.3418C62.1957 27.3963 60.5896 29.156 60.222 29.5971L60.1426 29.6953C60.1426 29.6953 61.6902 29.3355 63.411 29.0788C64.2716 28.9507 65.4186 28.8422 66.3506 28.7663L67.999 28.644Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.4625 29.9742C61.0742 30.4733 62.4751 31.4407 63.8978 31.188C65.7939 30.8514 68 28.6435 68 28.6435L66.3515 28.7636C65.4196 28.8395 64.2726 28.9479 63.412 29.0761C61.6912 29.3328 60.1436 29.6926 60.1436 29.6926L60.4625 29.9742Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.4783 10.3358C55.4783 10.3358 57.2017 9.77466 58.5566 10.2862C60.2934 10.9416 61.059 12.7339 61.2232 13.1718L61.258 13.2692C61.258 13.2692 60.1605 12.5732 58.9015 11.9098C58.272 11.5782 57.4163 11.1849 56.7154 10.8742L55.4783 10.3358Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.9359 13.3995C60.3291 13.6196 58.9867 13.9875 57.9579 13.4085C56.5856 12.6362 55.4783 10.3358 55.4783 10.3358L56.7185 10.8742C57.4172 11.1867 58.2729 11.5782 58.9046 11.9099C60.1636 12.5732 61.2611 13.2692 61.2611 13.2692L60.9359 13.3995Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.1182 21.7902C57.1182 21.7902 58.9616 22.6438 59.6852 24.0982C60.6123 25.9615 59.8757 28.0177 59.6772 28.5025L59.6326 28.6092C59.6326 28.6092 59.2614 27.2003 58.7327 25.695C58.4686 24.9428 58.0635 23.9665 57.728 23.1781L57.1182 21.7902Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.2668 28.4699C58.6007 28.1878 57.2181 27.466 56.813 26.2053C56.2741 24.5241 57.1173 21.7903 57.1173 21.7903L57.7258 23.1782C58.0631 23.9665 58.4664 24.9428 58.7305 25.695C59.2592 27.2003 59.6304 28.6092 59.6304 28.6092L59.2668 28.4699Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66.925 12.1674C66.925 12.1674 64.9008 12.3353 63.7217 13.4517C62.2106 14.8829 62.1964 17.0677 62.2164 17.5904L62.2231 17.7065C62.2231 17.7065 63.0552 16.511 64.0688 15.2794C64.5756 14.6638 65.2894 13.8857 65.877 13.2616L66.925 12.1674Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.6143 17.7011C63.3366 17.665 64.8829 17.4628 65.6958 16.4177C66.779 15.0245 66.9262 12.1674 66.9262 12.1674L65.8782 13.2616C65.2907 13.8865 64.5768 14.6638 64.07 15.2794C63.0564 16.511 62.2244 17.7065 62.2244 17.7065L62.6143 17.7011Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.6846 28.5753C53.6846 28.5753 55.5195 29.9118 55.9973 31.6635C56.6094 33.9081 55.3554 36.0022 55.0324 36.4906L54.9592 36.5973C54.9592 36.5973 54.8584 34.9723 54.6054 33.2072C54.4787 32.3247 54.2485 31.1667 54.05 30.2292L53.6846 28.5753Z" fill="#BF604B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.5897 36.3656C53.9205 35.9111 52.5611 34.8197 52.392 33.3492C52.1663 31.389 53.6858 28.5753 53.6858 28.5753L54.0512 30.227C54.2497 31.1644 54.4799 32.3224 54.6066 33.205C54.8596 34.9701 54.9604 36.595 54.9604 36.595L54.5897 36.3656Z" fill="#A9503C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0004 31.6421C26.0509 30.2056 30.6496 29.9033 34.1041 27.5856C37.5808 30.1993 42.1577 30.2091 42.2081 31.6421V32.514H26V31.6421H26.0004Z" fill="#FBCB22" />
                <path d="M40.9912 31.7908H27.219C27.1894 31.7908 27.161 31.779 27.1401 31.7581C27.1192 31.7372 27.1074 31.7088 27.1074 31.6792C27.1074 31.6496 27.1192 31.6212 27.1401 31.6003C27.161 31.5793 27.1894 31.5676 27.219 31.5676H40.9912C41.0208 31.5676 41.0491 31.5793 41.0701 31.6003C41.091 31.6212 41.1027 31.6496 41.1027 31.6792C41.1027 31.7088 41.091 31.7372 41.0701 31.7581C41.0491 31.779 41.0208 31.7908 40.9912 31.7908Z" fill="#ECA400" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.013 23.1241C32.013 23.1241 32.4761 23.8183 32.8607 25.0437C32.9865 25.4454 33.1145 25.87 33.1105 26.3244C33.1065 26.7789 32.9633 27.2016 32.8348 27.6056C32.448 28.8194 32.0121 29.5252 32.0121 29.5252C32.0121 30.6801 36.1946 30.6801 36.1946 29.5252C36.1946 29.5252 35.7588 28.8176 35.372 27.6056C35.2435 27.2039 35.1007 26.7829 35.0963 26.3244C35.0918 25.866 35.2203 25.445 35.3461 25.0437C35.7316 23.816 36.1938 23.1241 36.1938 23.1241C36.1857 22.5744 35.9619 22.05 35.5706 21.6641C35.1792 21.2782 34.6519 21.0619 34.1025 21.0619C33.5531 21.0619 33.0257 21.2782 32.6344 21.6641C32.2431 22.05 32.0193 22.5744 32.0112 23.1241H32.013Z" fill="#ECA400" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9607 14.3057C21.2473 13.979 19.22 12.5692 18.3148 10.5224C17.4096 8.47561 17.3677 5.50787 18.0052 5.48778C18.3666 5.49403 18.9814 5.51367 19.2914 5.48778C20.0271 5.48465 19.4253 6.03508 20.4822 7.81715C22.028 10.4237 25.0734 10.7304 25.0734 10.7304V8.49525C25.0734 8.49525 22.4443 8.99791 21.3124 6.88817C20.1806 4.77843 20.8788 3.88382 20.1431 3.90257C19.117 3.92712 17.5109 3.92043 16.6784 3.90257C15.7192 3.88204 16.0828 8.08366 16.988 10.5746C17.8932 13.0656 21.2504 16.257 25.9607 16.6619C25.9741 16.2258 25.9625 14.7816 25.9607 14.3057Z" fill="#ECA400" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.1351 14.3058C46.8485 13.979 48.8753 12.5692 49.7805 10.5224C50.6857 8.47562 50.7277 5.50787 50.0902 5.48778C49.7288 5.49403 49.1144 5.51368 48.8044 5.48778C48.0687 5.48466 48.6705 6.03509 47.6132 7.81716C46.0678 10.4238 43.0225 10.7304 43.0225 10.7304V8.49526C43.0225 8.49526 45.6515 8.99792 46.7834 6.88818C47.9152 4.77844 47.217 3.88695 47.9527 3.90436C48.9788 3.92892 50.5849 3.92222 51.4174 3.90436C52.3762 3.88383 52.013 8.08545 51.1078 10.5764C50.2026 13.0674 46.8454 16.2588 42.1351 16.6637C42.1199 16.2258 42.1333 14.7816 42.1351 14.3058Z" fill="#ECA400" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5913 8.7899V5.99492C23.5914 5.43996 23.8118 4.90777 24.204 4.51539C24.5963 4.12301 25.1282 3.90259 25.6828 3.90259H42.4129C42.6875 3.90253 42.9595 3.95661 43.2132 4.06174C43.4669 4.16687 43.6975 4.32098 43.8916 4.51529C44.0858 4.70959 44.2398 4.94027 44.3449 5.19415C44.45 5.44803 44.504 5.72013 44.504 5.99492V8.839C44.4852 17.3266 39.8075 24.2085 34.0479 24.2085C28.2771 24.2094 23.5913 17.3003 23.5913 8.7899Z" fill="#FBCB22" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2079 5.05344C45.2079 4.74821 45.0867 4.45549 44.871 4.23966C44.6554 4.02384 44.3628 3.90259 44.0578 3.90259H24.0378C23.7328 3.90259 23.4403 4.02384 23.2246 4.23966C23.0089 4.45549 22.8877 4.74821 22.8877 5.05344C22.8876 5.20464 22.9172 5.35439 22.975 5.49412C23.0327 5.63385 23.1174 5.76083 23.2242 5.86779C23.3311 5.97475 23.4579 6.0596 23.5975 6.11749C23.7371 6.17538 23.8867 6.20518 24.0378 6.20518H44.0578C44.3628 6.20518 44.6554 6.08393 44.871 5.8681C45.0867 5.65228 45.2079 5.35955 45.2079 5.05433V5.05344Z" fill="#FEEDB4" />
                <path d="M43.4677 5.25425H24.7415C24.6883 5.25425 24.6372 5.23308 24.5996 5.19541C24.5619 5.15774 24.5408 5.10664 24.5408 5.05336C24.5408 5.00009 24.5619 4.94899 24.5996 4.91131C24.6372 4.87364 24.6883 4.85248 24.7415 4.85248H43.4677C43.5209 4.85248 43.572 4.87364 43.6096 4.91131C43.6473 4.94899 43.6684 5.00009 43.6684 5.05336C43.6684 5.10664 43.6473 5.15774 43.6096 5.19541C43.572 5.23308 43.5209 5.25425 43.4677 5.25425Z" fill="#FBCB22" />
                <path d="M37.8262 17.8365C39.9131 15.7483 39.9131 12.3627 37.8262 10.2745C35.7393 8.18632 32.3558 8.18632 30.2689 10.2745C28.182 12.3627 28.182 15.7483 30.2689 17.8365C32.3558 19.9246 35.7393 19.9246 37.8262 17.8365Z" fill="#FEEDB4" />
                <path d="M34.048 20.2921C30.6127 20.2921 27.8154 17.4944 27.8154 14.0557C27.8154 10.617 30.6114 7.81934 34.048 7.81934C37.4845 7.81934 40.2805 10.617 40.2805 14.0557C40.2805 17.4944 37.4845 20.2921 34.048 20.2921ZM34.048 8.09834C30.7648 8.09834 28.0943 10.771 28.0943 14.0557C28.0943 17.3404 30.7648 20.0131 34.048 20.0131C37.3311 20.0131 40.0017 17.3408 40.0017 14.0557C40.0017 10.7706 37.3306 8.09834 34.048 8.09834Z" fill="#ECA400" />
                <path d="M34.8439 16.1601H35.7107C35.9454 16.1601 36.1228 16.2142 36.243 16.3226C36.3023 16.3772 36.349 16.4441 36.3798 16.5187C36.4106 16.5932 36.4248 16.6736 36.4214 16.7542C36.4214 17.1411 36.1491 17.3417 35.6045 17.356H32.863C32.3181 17.3414 32.0458 17.1408 32.0461 16.7542C32.0427 16.6736 32.0569 16.5932 32.0878 16.5187C32.1186 16.4441 32.1653 16.3772 32.2246 16.3226C32.3436 16.2142 32.521 16.1601 32.7568 16.1601H33.6438V12.6388C32.9002 13.2105 32.3989 13.4965 32.1398 13.4968C32.0782 13.4958 32.0174 13.482 31.9614 13.4564C31.9053 13.4307 31.8552 13.3937 31.8142 13.3477C31.7688 13.3033 31.7327 13.2502 31.7082 13.1916C31.6837 13.133 31.6712 13.0701 31.6714 13.0066C31.6714 12.8571 31.7182 12.7468 31.8119 12.6767C31.969 12.5708 32.1353 12.4794 32.3089 12.4035C32.6152 12.2668 32.9016 12.0891 33.1601 11.8754C33.3721 11.6913 33.5618 11.483 33.7254 11.2549C33.8896 11.0263 33.9965 10.8856 34.0462 10.8326C34.0961 10.7799 34.1898 10.7531 34.3277 10.7531C34.3999 10.751 34.4716 10.7663 34.5367 10.7978C34.6018 10.8293 34.6583 10.876 34.7015 10.9339C34.7952 11.053 34.8422 11.2187 34.8425 11.4312L34.8439 16.1601Z" fill="#BF3F34" />
                <path d="M26.8259 16.7279C26.7438 16.7279 26.6635 16.7037 26.5951 16.6584C26.5266 16.6131 26.473 16.5486 26.4409 16.473C24.6162 12.1772 24.8067 8.10819 24.8156 7.93677C24.8185 7.88189 24.8322 7.82814 24.8559 7.77856C24.8796 7.72899 24.9128 7.68457 24.9537 7.64785C24.9945 7.61112 25.0422 7.5828 25.094 7.56451C25.1458 7.54622 25.2006 7.53831 25.2555 7.54125C25.3103 7.54418 25.364 7.55789 25.4136 7.58159C25.4631 7.6053 25.5075 7.63854 25.5442 7.67941C25.5809 7.72028 25.6092 7.76799 25.6275 7.81981C25.6458 7.87162 25.6537 7.92654 25.6508 7.98141C25.6508 8.03141 25.467 12.0406 27.2122 16.1463C27.2388 16.2098 27.2493 16.279 27.2428 16.3476C27.2363 16.4161 27.2129 16.4821 27.1748 16.5395C27.1368 16.5969 27.0851 16.644 27.0245 16.6766C26.9638 16.7093 26.8961 16.7264 26.8272 16.7266L26.8259 16.7279Z" fill="#FEEDB4" />
                <path d="M28.7046 20.0367C28.6392 20.0368 28.5748 20.0215 28.5165 19.9921C28.4581 19.9627 28.4075 19.9201 28.3686 19.8675C27.9245 19.2674 27.5231 18.6369 27.1672 17.9806C27.1177 17.8833 27.1081 17.7706 27.1404 17.6663C27.1728 17.5621 27.2445 17.4746 27.3403 17.4225C27.4362 17.3704 27.5486 17.3579 27.6536 17.3875C27.7586 17.4171 27.8478 17.4865 27.9024 17.581C28.2392 18.2023 28.6193 18.799 29.0401 19.3667C29.0868 19.4289 29.1153 19.5029 29.1222 19.5804C29.1292 19.658 29.1145 19.7359 29.0796 19.8055C29.0447 19.875 28.9912 19.9335 28.9249 19.9743C28.8587 20.0151 28.7823 20.0365 28.7046 20.0363V20.0367Z" fill="#FEEDB4" />
              </svg>
              <h1 className='font-space text-[1.25rem] font-medium text-[#151515] ml-[0.62rem]'>How to climb the Leaderboard ranks</h1>
            </div>
            <ul className='list-disc font-space'>
              <li className='mb-[1.7rem]'>Introduce 10 new active traders in that quarter (3 months).</li>
              <li>Earn a minimum cumulative brokerage commission of N100,000 in that quarter (3 months).</li>
            </ul>
          </div>
        </div>
        <div className='border border-[#8080804D] mt-[2.5rem] mb-[8.7rem] rounded-[0.3125rem] lg:w-[40.5rem] pl-[1.88rem] pr-[2.81rem] pt-[1.62rem] pb-[1.5rem]'>
          <h1 className='font-space text-[1.375rem] font-medium text-[#151515] mb-[0.62rem]'>Refer a Trader</h1>
          <p className='font-space text-[0.875rem] text-[#151515]'>Your unique referral link</p>
          <div className='mt-[1.25rem] flex items-center gap-[1rem]'>
            <div className='py-[1rem] pl-[1.36rem] bg-[#E6F4EF] rounded-[0.3125rem] w-[25.375rem]'>
              <p>{details.referral_code}</p>
            </div>
            <CopyToClipboard text={details.referral_code}
              onCopy={() => setCopied(true)}>
              <button className='bg-[#02915D] font-space px-[1.75rem] py-[0.94rem] flex items-center gap-[1rem] text-white rounded-[0.3125rem]'>
                <p>{copied ? "Copied" : "Copy"}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M6.04174 9.99999C6.04174 9.65832 6.32507 9.37499 6.66674 9.37499H9.16674V6.24999C9.16674 5.79166 8.79174 5.41666 8.33341 5.41666H6.47507C3.85007 5.41666 1.56674 7.56666 1.66674 10.1833C1.71674 11.375 2.21674 12.45 3.00841 13.2417C3.84174 14.0667 4.98341 14.5833 6.25007 14.5833H8.33341C8.79174 14.5833 9.16674 14.2083 9.16674 13.75V10.625H6.66674C6.32507 10.625 6.04174 10.3417 6.04174 9.99999Z" fill="white" fill-opacity="0.8" />
                  <path d="M16.9916 6.75832C16.1583 5.93332 15.0166 5.41666 13.7499 5.41666H11.6666C11.2083 5.41666 10.8333 5.79166 10.8333 6.24999V9.37499H13.3333C13.6749 9.37499 13.9583 9.65832 13.9583 9.99999C13.9583 10.3417 13.6749 10.625 13.3333 10.625H10.8333V13.75C10.8333 14.2083 11.2083 14.5833 11.6666 14.5833H13.5249C16.1499 14.5833 18.4333 12.4333 18.3249 9.81666C18.2833 8.62499 17.7749 7.54999 16.9916 6.75832Z" fill="white" fill-opacity="0.8" />
                  <path d="M10.8334 9.375H9.16675V10.625H10.8334V9.375Z" fill="white" fill-opacity="0.8" />
                </svg>
              </button>
            </CopyToClipboard>
          </div>
          <div className="flex gap-4 justify-start mt-5">
            <FacebookShareButton url={"hello"} hashtag="#Anyon" quote="Join Anyon with my referral link">
              <div className='bg-[#02915D1A] p-[0.53rem] rounded-[0.3125rem]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                  <path d="M18.0493 4.51613L15.409 4.51294C12.8485 4.51294 11.1945 6.20927 11.1945 8.83763V10.8303H8.54541V14.4362H11.1945L11.1913 22.0832H14.8979L14.9011 14.4362H17.9407L17.9383 10.8311H14.9011V9.14031C14.9011 8.32729 15.0935 7.91598 16.1517 7.91598L18.0414 7.91519L18.0493 4.51613Z" fill="black" />
                </svg>
              </div>
            </FacebookShareButton>
            <LinkedinShareButton url={"hello"} className='bg-[#02915D1A]' hashtag="#Anyon" quote="Join Anyon with my referral link">
              <div className='bg-[#02915D1A] p-[0.78rem] rounded-[0.3125rem]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g clip-path="url(#clip0_54_5196)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4104 17.8163H14.0028V11.8538C14.0028 10.2182 13.2813 9.30566 11.9873 9.30566C10.5791 9.30566 9.74338 10.2566 9.74338 11.8538V17.8163H6.33581V6.74166H9.74338V7.98706C9.74338 7.98706 10.8125 6.11106 13.2217 6.11106C15.6317 6.11106 17.4104 7.58172 17.4104 10.6247V17.8163ZM2.45288 4.97049C1.30368 4.97049 0.372559 4.03176 0.372559 2.87403C0.372559 1.71716 1.30368 0.778412 2.45288 0.778412C3.60123 0.778412 4.53235 1.71716 4.53235 2.87403C4.5332 4.03176 3.60123 4.97049 2.45288 4.97049ZM0.372559 17.8163H4.63202V6.74166H0.372559V17.8163Z" fill="black" />
                  </g>
                  <defs>
                    <clipPath id="clip0_54_5196">
                      <rect width="17.0379" height="17.0379" fill="white" transform="translate(0.372559 0.778412)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </LinkedinShareButton>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Home