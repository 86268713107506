import React, { useEffect, useState } from 'react'
import WalletTable from '../Tables/WalletTable'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import axios from '../../axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactSupportModal from '../Modals/ContactSupportModal';
import WithdrawSuccess from "../../assets/Group 448.svg"
import WithdrawFailed from "../../assets/Group 450.svg"

const header = [
  {
    key: "#",
    label: "#"
  },
  {
    key: "username",
    label: "Username"
  },
  {
    key: "date",
    label: "Date"
  },
  {
    key: "type",
    label: "Type"
  },
  {
    key: "rewards",
    label: "Rewards"
  },
]


function Wallet() {
  // const params = useLocation()
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const link = searchParams.get("withdraw")
  const link1 = searchParams.get("successful")
  console.log(link)
  const [walletBalance, setWalletBalance] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [todayIncome, setTodayIncome] = useState(0)
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [percent, setPercent] = useState(0)
  const [todayPercent, setTodayPercent] = useState(0)

  // console.log(amount)

  // const showRequestSuccess = (message) => {
  //   toast.success(message, {
  //     position: toast.POSITION.BOTTOM_CENTER,
  //   });
  // };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white"
      }
    });
  };

  useEffect(() => {
    axios.get("/affilate/reward-activities/").then(res => {
      console.log(res)
      if(res && res.data){
        setPercent(res.data.last_60_to_30_days_balance_change_percentage)
        setTodayPercent(res.data.today_income_change_percentage)
        setWalletBalance(res.data.wallet_balance)
        setTotalIncome(res.data.total_income)
        setTodayIncome(res.data.today_income)
        setActivities(res.data.reward_activities)
      }else{
        window.location.reload()
      }
    }).catch(err => {
      console.log(err)
    })
  }, [])

  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    bankName: ""
  });


  useEffect(() => {
    axios.get("auth/profile/").then(res => {
      console.log(res)
      setBankDetails({ accountNumber: res.data.detail.account_number, bankName: res.data.detail.bank_name })
    }).catch(err => {
      console.log(err)
    });


  }, [])

  const makeWithdrawal = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      axios.post("/affilate/withdraw/", {
        amount
      }).then(res => {
        console.log(res)
        if(res.data === "Insufficient balance"){
          showRequestFailed(res.data)
        }else{
          navigate("/wallet?withdraw=yes&successful=yes")
          // showRequestSuccess("Successfully sent withdrawal request.")
        }
        setLoading(false)
      }).catch(err => {
        console.log(err)
        navigate("/wallet?withdraw=yes&successful=no")
        // showRequestFailed("Failed to send withdrawal request.")
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='lg:px-[4.6rem] px-[1rem] pt-[4.5rem] pb-[5rem]'>
      <ToastContainer />
      {link !== "yes" && link1 !== "no" && link1 !== "yes" &&
        <div>
          <div className='flex flex-col lg:flex-row items-center gap-[1.87rem]'>
            <div className='flex items-start lg:w-[51.6%] w-[100%] border rounded-[0.3125rem] pt-[2rem] pb-[2.38rem] pl-[2.25rem] pr-[2.5rem] justify-between'>
              <div>
                <p className='mb-[3.56rem] font-space text-[1.125rem] text-[#151515] font-medium'>Wallet balance</p>
                <p className='font-space text-[3rem] text-[#00BA77]'><span className='text-[#151515]'>N</span>{walletBalance ? walletBalance : 0}</p>
                <p className={`lg:hidden font-space ${percent >= 0 ? "text-[#00BA77]" : "text-red-500"} text-[0.8125rem]`}>{percent >= 0 ? `+${percent}%` : `-${percent}%`} in the last 30 days</p>
              </div>
              <div className='flex flex-col items-end'>
                <Link to="/wallet?withdraw=yes">
                  <button className='mb-[4.56rem] py-[0.75rem] px-[1rem] bg-[#00BA77] text-white font-space rounded-[0.1875rem]'>Withdraw</button>
                </Link>
                <p className={`hidden lg:block font-space ${percent >= 0 ? "text-[#00BA77]" : "text-red-500"} text-[0.8125rem]`}>{percent >= 0 ? `+${percent}%` : `-${percent}%`} in the last 30 days</p>
              </div>
            </div>
            <div className='lg:w-[24.2%] w-[100%] border rounded-[0.3125rem] flex flex-col items-center pt-[1.87rem] pb-[3.06rem] gap-[3.25rem]'>
              <p className='font-space text-[1.125rem] text-[#151515] font-medium'>Total income</p>
              <p className='whitespace-nowrap font-space text-[3rem] text-[#00BA77]'><span className='text-[#151515]'>N</span> {totalIncome ? totalIncome : 0}<span className={`text-[0.8125rem] ${percent >= 0 ? "text-[#00BA77]" : "text-red-500"}`}>{percent >= 0 ? `(+${percent}%)` : `(-${percent}%)`}</span></p>
            </div>
            <div className='lg:w-[24.2%] w-[100%] border rounded-[0.3125rem] flex flex-col items-center pt-[1.87rem] pb-[3.06rem] gap-[3.25rem]'>
              <p className='font-space text-[1.125rem] text-[#151515] font-medium'>Today's income</p>
              <p className='font-space text-[3rem] text-[#00BA77]'><span className='text-[#151515]'>N</span> {todayIncome ? todayIncome : 0}<span className={`text-[0.8125rem] ${percent >= 0 ? "text-[#00BA77]" : "text-red-500"}`}>{todayPercent >= 0 ? `(+${todayPercent}%)` : `(-${todayPercent}%)`}</span></p>
            </div>
          </div>
          <WalletTable head={header} body={activities} />
        </div>
      }
      {link === "yes" && link1 !== "no" && link1 !== "yes" && <div className='flex flex-col items-center'>
        <h1 className='font-space text-[1.5625rem] font-medium text-[#151515] mb-[1rem]'>Withdraw</h1>
        <p className='font-space text-[0.8125rem] text-[#151515] mb-[3.12rem]'>Referral wallet balance <span className='text-[#00BA77]'>N{walletBalance ? walletBalance.toLocaleString() : 0}</span></p>
        <form action="" className='flex flex-col gap-[1.5rem] lg:w-[445px] w-[100%]'>
          <div className='flex flex-col'>
            <label className='font-space text-[0.875rem] text-[#151515] mb-[0.88rem]' htmlFor="">Bank name</label>
            <input value={bankDetails.bankName} disabled type="text" className='border py-[1rem] pl-[1.25rem] rounded-[0.3125rem] lg:w-[27.75rem]' />
          </div>
          <div className='flex flex-col'>
            <label className='font-space text-[0.875rem] text-[#151515] mb-[0.88rem]' htmlFor="">Account number</label>
            <input value={bankDetails.accountNumber} disabled type="text" className='border py-[1rem] pl-[1.25rem] rounded-[0.3125rem] lg:w-[27.75rem]' />
          </div>
          <div className='flex flex-col'>
            <label className='font-space text-[0.875rem] text-[#151515] mb-[0.88rem]' htmlFor="">Enter amount</label>
            <input onChange={(e) => setAmount(e.target.value)} type="number" className={`border py-[1rem] pl-[1.25rem] outline-none  rounded-[0.3125rem] lg:w-[27.75rem] ${amount > walletBalance && "border-red-700 text-red-700"}`} />
          </div>
          {amount > walletBalance && <p className='text-center' style={{color:"#B20F19"}}>You do not have enough funds in your wallet to withdraw!</p>}
          <button disabled={loading} onClick={makeWithdrawal} className='font-space text-white bg-[#00BA77] py-[1rem] rounded-[0.3125rem]'>Withdraw</button>
        </form>
        <p onClick={() => setIsOpen(true)} className='font-space text-[#00BA77] mt-[1rem] cursor-pointer'>+ Change Payout details</p>
      </div>}

      {link1 === "no" && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "2.94rem", paddingBottom: "2.38rem" }}>
          <img src={WithdrawFailed} alt="" width={300} height={300} />
          <h1 className='font-space text-[2rem] font-semibold my-5'>Transaction failed</h1>
          <p className='font-space text-[1rem] font-normal mb-5' style={{ textAlign: "center" }}>Oops! an error has occurred. </p>
          <Link to="/wallet?withdraw=yes">
            <button style={{ background: "#00BA77", border: "1px solid #80808080", display: "flex", alignItems: "center", color: "white", gap: "0.62rem", padding: "0.5rem 6rem", borderRadius: "0.3125rem" }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 9.99994C18.75 9.83418 18.6841 9.67521 18.5669 9.558C18.4497 9.44079 18.2907 9.37494 18.125 9.37494H3.38374L7.31749 5.44244C7.43485 5.32508 7.50078 5.16591 7.50078 4.99994C7.50078 4.83397 7.43485 4.6748 7.31749 4.55744C7.20013 4.44008 7.04096 4.37415 6.87499 4.37415C6.70902 4.37415 6.54985 4.44008 6.43249 4.55744L1.43249 9.55744C1.37428 9.6155 1.32811 9.68447 1.2966 9.7604C1.26509 9.83633 1.24887 9.91773 1.24887 9.99994C1.24887 10.0822 1.26509 10.1636 1.2966 10.2395C1.32811 10.3154 1.37428 10.3844 1.43249 10.4424L6.43249 15.4424C6.4906 15.5006 6.55958 15.5466 6.63551 15.5781C6.71143 15.6095 6.79281 15.6257 6.87499 15.6257C7.04096 15.6257 7.20013 15.5598 7.31749 15.4424C7.43485 15.3251 7.50078 15.1659 7.50078 14.9999C7.50078 14.834 7.43485 14.6748 7.31749 14.5574L3.38374 10.6249H18.125C18.2907 10.6249 18.4497 10.5591 18.5669 10.4419C18.6841 10.3247 18.75 10.1657 18.75 9.99994Z" fill="#F1F1F1" />
              </svg> */}
              <p>Retry</p>
            </button>
          </Link>
        </div>}

        {link1 === "yes" && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "2.94rem", paddingBottom: "2.38rem" }}>
          <img src={WithdrawSuccess} alt="" width={300} height={300} />
          <h1 className='font-space text-[2rem] font-semibold my-5'>Successful withdrawal</h1>
          <p className='font-space text-[1rem] font-normal mb-5' style={{ textAlign: "center" }}>We are processing this transaction to your bank account.</p>
          <Link to="/">
            <button style={{ background: "#00BA77", border: "1px solid #80808080", display: "flex", alignItems: "center", color: "white", gap: "0.62rem", padding: "0.5rem 6rem", borderRadius: "0.3125rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 9.99994C18.75 9.83418 18.6841 9.67521 18.5669 9.558C18.4497 9.44079 18.2907 9.37494 18.125 9.37494H3.38374L7.31749 5.44244C7.43485 5.32508 7.50078 5.16591 7.50078 4.99994C7.50078 4.83397 7.43485 4.6748 7.31749 4.55744C7.20013 4.44008 7.04096 4.37415 6.87499 4.37415C6.70902 4.37415 6.54985 4.44008 6.43249 4.55744L1.43249 9.55744C1.37428 9.6155 1.32811 9.68447 1.2966 9.7604C1.26509 9.83633 1.24887 9.91773 1.24887 9.99994C1.24887 10.0822 1.26509 10.1636 1.2966 10.2395C1.32811 10.3154 1.37428 10.3844 1.43249 10.4424L6.43249 15.4424C6.4906 15.5006 6.55958 15.5466 6.63551 15.5781C6.71143 15.6095 6.79281 15.6257 6.87499 15.6257C7.04096 15.6257 7.20013 15.5598 7.31749 15.4424C7.43485 15.3251 7.50078 15.1659 7.50078 14.9999C7.50078 14.834 7.43485 14.6748 7.31749 14.5574L3.38374 10.6249H18.125C18.2907 10.6249 18.4497 10.5591 18.5669 10.4419C18.6841 10.3247 18.75 10.1657 18.75 9.99994Z" fill="#F1F1F1" />
              </svg>
              <p>Return to Home</p>
            </button>
          </Link>
        </div>}
      <ContactSupportModal isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div>
  )
}

export default Wallet
